<template>
    <div class="app-container">
        <vxe-toolbar class="finished" perfect style="background-color:#fff;padding-left:10px;">
            <template #buttons>
                <el-row :gutter="10">
                    <el-col :span="4">
                        <el-input v-model="search.title" size="medium" style="width:100%" clearable
                            placeholder="请输入论文题目">
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-input v-model="search.institution" size="medium" style="width:100%" clearable
                            placeholder="请输入送审单位">
                        </el-input>
                    </el-col>
                    <el-col :span="6">
                        <el-date-picker v-model="search.time" size="medium" style="width:100%" type="daterange"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="6" style="padding-top:2px;">
                        <!-- <el-button type="primary" size="small" @click="searchPaper">搜索</el-button>
                        <el-button type="primary" size="small" @click="resetHandle">重置</el-button> -->
                        <el-button type="primary" size="small" icon="el-icon-search" @click="searchPaper">
                            搜索
                        </el-button>
                        <el-button type="success" size="small" icon="el-icon-refresh" @click="resetHandle">
                            重置
                        </el-button>
                    </el-col>
                </el-row>
            </template>
        </vxe-toolbar>
        <vxe-table :header-cell-style="headerCellStyle" stripe round align="center" ref="paperTable"
            highlight-current-row highlight-hover-row :keyboard-config="{isArrow: true}" :loading='loading'
            :data="paperList" class="mytable-scrollbar finishedList-table">
            <vxe-column type='seq' title="序号" width="8%"></vxe-column>
            <vxe-column field="title" title="题目" :show-overflow="'tooltip'"></vxe-column>
            <vxe-column field="type" title="论文类型" :show-overflow="'tooltip'" width="10%"></vxe-column>
            <vxe-column field="institutionId" title="送审单位" :show-overflow="'tooltip'" width="20%"></vxe-column>
            <vxe-column field="submitTime" title="评审日期" width="15%" sortable>
                <template #default='{row}'>
                    <div>
                        {{row.submitTime}}
                        <!-- {{row.submitTime.substr(0,10)}} -->
                    </div>
                </template>
            </vxe-column>
            <vxe-column title="论文原件" width="8%">
                <template #default="{ row }">
                    <el-button type="text" size="small" @click="handleLookReview(row.paperUrl)">查看</el-button>

                </template>
            </vxe-column>
            <vxe-column title="评阅书" width="8%">
                <template #default="{ row }">
                    <!-- <el-button type="text" size="small" @click="handleLookReview(row.reviewUrl)"
                        v-if='row.isExtracted == 1'>查看</el-button>
                    <span v-else>生成中……</span> -->
                    <el-button type="text" size="small" @click="lookReview(row.paperId,row.reviewId)">查看</el-button>
                </template>
            </vxe-column>
            <vxe-column title="论文附件" width="8%" >
                <template #default="{ row }">
                    <div v-if="row.annexUrl">
<!--                        <el-link type="primary" @click="downloadAnnex(row)">{{row.annexUrl}}</el-link>-->
                        <img @click="downloadAnnex(row)" width="20px" height="20px" style="margin-left: 5px;margin-bottom: 0px !important;" src="./img/zip.png"/>
                    </div>
                    <div v-else>无</div>
                </template>
            </vxe-column>
        </vxe-table>
        <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
            :total="page.total" :page-sizes="[10, 20, 50, 100]"
            :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
            @page-change="handlePageChange">
            <template #left>
                <vxe-button size="small" @click="firstPage">首页
                </vxe-button>
            </template>
        </vxe-pager>

        <vxe-modal v-model="reviewModel" :position="{top: '0px'}" width="800" height="100%" :show-footer='true'
            show-zoom resize :transfer='true'>
            <template #title>
                评阅书
            </template>
            <!-- 数值 -->
            <div class="table">
                <vxe-table border align="center" ref="dataTable" :cell-style='cellStyleNumber'
                    :cell-class-name="cellClassName" style="width: 100%;" :span-method="mergeMethod" :data="dataTable"
                    element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
                    <vxe-column field="firstIndex" title="指标" width="30%">
                        <template #default="{row}">
                            <template v-if="row.type == '4'">
                                <div v-html="row.firstIndex + '（满分 ' + row.score + ' 分）'"> </div>
                                <!-- {{row.firstIndex}}（{{row.score}} 分） -->
                            </template>
                            <template v-else-if="row.type == '6'">
                                <div class="txtareaCom" v-html="row.comment">
                                    <!-- {{row.comment}} -->
                                </div>
                            </template>
                            <template v-else-if="row.type == '5'">
                                <div class="txtareaCom">
                                    <span style="color:#000">{{row.firstIndex}}
                                    </span><span v-if="row.comment">{{'（'+row.comment + '）'}}</span>
                                </div>
                                <div class="ptext" v-html="row.content">
                                </div>
                            </template>
                            <template v-else>
                                <div v-html="row.firstIndex"></div>
                            </template>
                        </template>
                    </vxe-column>
                    <vxe-column field="secondIndex" title="评价要素" width="50%">
                        <template #default="{row}">
                            <template v-if="row.type == '2'">
                                <!-- 单选 -->
                                <vxe-radio v-model="singleSelects[row.group]" class="tableRadio" :label="row.score"
                                    :content="content(row.score,row.leftRange,row.rightRange)" :disabled='true'>
                                </vxe-radio>
                                <div v-if="row.comment" v-html="row.comment">
                                    <!-- {{row.comment}} -->
                                </div>
                            </template>
                            <template v-else-if="row.type == '3'">
                                <!-- 多选 -->
                                <vxe-checkbox v-model="row.content" class="tableCheckbox" :content="row.score"
                                    :disabled='true'></vxe-checkbox>
                                <div v-if="row.comment" v-html="row.comment">
                                    <!-- {{row.comment}} -->
                                </div>
                            </template>
                            <template v-else>
                                <div v-html="row.secondIndex + '（满分 ' + row.score + ' 分）'"></div>
                            </template>
                        </template>
                    </vxe-column>
                    <vxe-column field="content" title="评价" width="20%">
                        <template #default="{row}">
                            {{row.content}} 分
                        </template>
                    </vxe-column>
                </vxe-table>
            </div>
        </vxe-modal>

    </div>
</template>

<script>
    // import dateFormat from '../../../public/tools/date'
    export default {
        name: 'FinishedList',
        data() {
            return {
                loading: false,
                //搜索条件
                search: {
                    title: '',
                    institution: "",
                    time: ['', '']
                },
                institutionList: [],
                //表格数据
                paperList: [],
                // 分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageSizes: [1, 5, 10, 15, 20],
                    total: 10
                },
                //处理每页几条数据
                currentSize: 5,
                perPageSize: [5, 10, 20, 50],
                //专家ID
                expertId: '1014100661',
                groupSelected: {},
                reviewModel: false,
                numberTable: [],
                totalTable: [],
                singleTable: [],
                singleSelects: {},
                mulTable: [],
                textTable: [],
                commentTable: [],
                dataTable: [],
                replaceMap: null
            }
        },
        created() {
            this.getPaperList();
            // this.getUniversityList();
            this.getReplaceMap();
        },
        methods: {
            getReplaceMap() {
                this.$api.reviewPage.getReplaceMap()
                    .then(res => {
                        this.replaceMap = res.data.data;
                    })
            },
            getRaw(params) {
                const loading = this.$loading({
                    lock: true,
                    text: '打开中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$api.reviewPage.getPaperRaw(params).then(res => {
                    let data = res.data;
                    const blob = new Blob([data], {
                        type: 'application/pdf;charset=utf-8'
                    });
                    console.log(blob);
                    let url = window.URL || window.webkitURL;
                    // 将blob对象创建新的url
                    const blobUrl = url.createObjectURL(blob);
                    // 将url传送到PDF.js
                    // this.src = blobUrl;
                    loading.close();
                    window.open(blobUrl)
                })
            },
            //获取论文列表
            getPaperList() {
                this.loading = true;
                let params = {
                    institution: this.search.institution,
                    title: this.search.title,
                    startTime: this.search.time[0] ? this.dateFormat(this.search.time[0]) : '',
                    endTime: this.search.time[1] ? this.dateFormat(this.search.time[1]) : '',
                    pageIndex: this.page.currentPage,
                    pageSize: this.page.pageSize
                };
                console.log(params);
                this.$api.unfinished.getReviewedRecords(params)
                    .then(res => {
                        if (res.data.code == 200) {
                          console.log(res.data.data)
                            this.paperList = res.data.data;
                            this.page.total = res.data.count;
                            this.loading = false;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            //搜索论文
            searchPaper() {
                this.getPaperList();
            },
            //重置
            resetHandle() {
                this.search = {
                    title: '',
                    institution: "",
                    time: ['', '']
                };
            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                this.page.currentPage = currentPage;
                this.page.pageSize = pageSize;
                this.getPaperList();
            },
            //每页多少数据改变
            handleChangePageSize(val) {
                this.page.pageSize = val;
                this.page.currentPage = 1;
                this.getPaperList();
            },
            firstPage() {
                this.page.currentPage = 1;
                this.getPaperList();
            },
            endPage() {
                this.page.currentPage = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this
                    .page
                    .pageSize == 0 ? 0 : 1);
                this.getPaperList();
            },
            dateFormat: function (time) {
                var datetime = new Date(time);
                // datetime.setTime(time);
                var year = datetime.getFullYear();
                var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() +
                    1;
                var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
                return year + "-" + month + "-" + date;
            },
            //获取评阅书
            handleLookReview(url) {
                this.getRaw({
                    path: url
                })
                // window.open(url);
            },
            //获取论文原件评审信息
            getPaper(paperId, reviewId) {
                let params = {
                    paperId: paperId,
                    reviewId: reviewId
                };
                this.$api.reviewPage.getPaper(params)
                    .then(res => {
                        if (res.data.code == 200) {
                            let tem = res.data.data.template;
                            this.numberTable = tem['0'] ? tem['0'] : [];
                            this.singleTable = tem['2'] ? tem['2'] : [];
                            this.mulTable = tem['3'] ? tem['3'] : [];
                            this.totalTable = tem['4'] ? tem['4'] : [];
                            let textTable = tem['5'] ? tem['5'] : [];
                            this.commentTable = tem['6'] ? tem['6'] : [];
                            this.changeSingle();
                            this.changeMul();
                            this.changeText(textTable);
                            this.changeDataTable();
                            this.reviewModel = true;
                        }
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    })
            },
            // 合并数值单选特征
            changeDataTable() {
                let arr = [];
                this.numberTable.forEach(item => {
                    arr.push(item);
                });
                this.totalTable.forEach(item => {
                    arr.push(item);
                });
                this.singleTable.forEach(item => {
                    arr.push(item);
                });
                this.mulTable.forEach(item => {
                    arr.push(item);
                });
                this.textTable.forEach(item => {
                    arr.push(item);
                });
                this.commentTable.forEach(item => {
                    arr.push(item);
                })
                let n = arr.length;
                for (let i = 0; i < n; i++) {
                    for (let j = i + 1; j < n; j++) {
                        let irank = arr[i].innerRanking;
                        let jrank = arr[j].innerRanking;
                        if (arr[i].type == '2' || arr[j].type == '3') irank /= 100;
                        if (arr[j].type == '2' || arr[j].type == '3') jrank /= 100;
                        if (irank > jrank) {
                            let t = arr[i];
                            arr[i] = arr[j];
                            arr[j] = t;
                        }
                    }
                }
                this.dataTable = arr;
            },
            changeSingle() {
                let groupSelected = {};
                this.singleTable.forEach((item, index) => {
                    // this.scoreIndex[item.score] = index;
                    if (!groupSelected.hasOwnProperty(item.group) || groupSelected[item
                            .group] == '') {
                        groupSelected[item.group] = item.content == '1' ? item.score : '';
                    }
                });
                this.singleSelects = groupSelected;
            },
            changeMul() {
                this.mulTable.forEach(item => {
                    item.content = item.content && item.content == '1' ? true : false;
                })
            },
            changeText(textTable) {
                String.prototype.replaceAll = function (s1, s2) {
                    return this.replace(new RegExp(s1, "gm"), s2);
                };
                for (let i = 0; i < textTable.length; i++) {
                    textTable[i].content = textTable[i].content.replaceAll(
                        '<w:br/>&#10;&#10;&#10;&#10;', '<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
                    for (let j = this.replaceMap.length - 1; j >= 0; j--) {
                        let item = this.replaceMap[j];
                        textTable[i].content = textTable[i].content.replaceAll(item.value, item.key)
                    }
                    let t = textTable[i].content.trim();
                    if (t) textTable[i].content = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;' + t;
                }
                this.textTable = textTable;
            },
            content(score, leftRange, rightRange) {
                let content = score;
                if (leftRange == 0 && rightRange == 100) return content;
                if (!rightRange) return content;
                content += '（' + leftRange + '-' + rightRange + ' 分' + '）';
                return content;
            },
            // 查看评阅书
            lookReview(paperId, reviewId) {

                this.getPaper(paperId, reviewId);
            },
            //下载评阅书
            handleDownloadReview() {

            },
            // headerCellClassName({
            //     column,
            //     columnIndex
            // }) {
            //     return 'vxe-table-thead'
            // },
            headerCellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {
                return "background-color:#e0e0e0f1"
            },
            //合并表格
            mergeMethod({
                row,
                _rowIndex,
                column,
                _columnIndex,
                visibleData
            }) {
                let firstIndex = row['firstIndex'];
                let type = row['type'];
                console.log(firstIndex);
                // 总分型
                if (row.type == '4') {
                    let rows = 1,
                        cols = 1;
                    if (column.property == 'firstIndex') {
                        rows = 1;
                        cols = 2;
                    } else if (_columnIndex == 1) {
                        rows = 0;
                        cols = 0;
                    } else {
                        rows = 1;
                        cols = 1;
                    }
                    return {
                        rowspan: rows,
                        colspan: cols
                    }
                }
                if (row.type == '5' || row.type == '6') {
                    let rows = 1,
                        cols = 1;
                    if (column.property == 'firstIndex') {
                        rows = 1;
                        cols = 3;
                    } else {
                        rows = 0;
                        cols = 0;
                    }
                    return {
                        rowspan: rows,
                        colspan: cols
                    }
                }
                if (column.property == 'firstIndex') {
                    let prevRow = visibleData[_rowIndex - 1];
                    let nextRow = visibleData[_rowIndex + 1];
                    if (prevRow && prevRow['firstIndex'] == firstIndex) {
                        return {
                            rowspan: 0,
                            colspan: 0
                        };
                    } else {
                        // 一级指标相同合并 从第一行合并到第4行
                        let countRowspan = 1;
                        let countColspan = 1;
                        while (nextRow && nextRow['firstIndex'] == firstIndex && nextRow['type'] == type) {
                            nextRow = visibleData[++countRowspan + _rowIndex]
                        }
                        // if (row.type == 5 && _columnIndex == 1) {
                        //     countColspan = 2;
                        // }
                        if (countRowspan >= 1) {
                            return {
                                rowspan: countRowspan,
                                colspan: countColspan
                            };
                        }
                    }
                }
                // 单选型
                if (row.type == '2' || row.type == '3') {
                    let rows = 1,
                        cols = 1;
                    if (_columnIndex == 1) {
                        rows = 1;
                        cols = 2;
                    } else {
                        rows = 0;
                        cols = 0;
                    }
                    return {
                        rowspan: rows,
                        colspan: cols
                    }
                }
            },
            // 表格样式
            cellStyleNumber({
                row,
                rowIndex,
                column,
                columnIndex
            }, tableData) {
                //设置状态颜色
                let styles = {};
                if (row.type == '5' || row.type == '6') {
                    styles['padding'] = '0px';
                }
                if ((row.type == '2' || row.type == '3') && columnIndex == 1) {
                    styles['text-align'] = 'left';
                }
                if (columnIndex == 0) {
                    styles['border-bottom'] = '1px solid rgb(153, 153, 153)';
                }
                if (rowIndex == this.dataTable.length - 1) {
                    styles['border-bottom'] = '1px solid rgb(153, 153, 153)';
                    return styles;
                }
                if (this.dataTable[rowIndex].type != this.dataTable[rowIndex + 1].type || this.dataTable[rowIndex]
                    .firstIndex != this.dataTable[rowIndex + 1].firstIndex) {
                    styles['border-bottom'] = '1px solid rgb(153, 153, 153)';
                }
                return styles;
            },
            cellClassName({
                row,
                column
            }) {
                if (row.type == '5' || row.type == '6') {
                    return 'mycell';
                }
            },
            downloadAnnex(row){
                this.$message("附件下载");
                console.log(row);
                this.$api.reviewPage.downloadFile({paperId:""+row.paperId} ).then(res => {

                    //   方法二
                    console.log(res)
                    console.log(typeof res.data)

                    let blob = new Blob([res.data], {type: 'application/octet-stream;charset=utf-8'});
                    console.log(blob,5)
                    let link = document.createElement("a");
                    let url = window.url || window.webkitURL;
                    link.href = url.createObjectURL(blob);
                    //重命名文件
                    link.download = row.annexUrl;

                    //释放内存
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                  url.revokeObjectURL(link.href);
                }).catch(e => {
                    this.$message("附件下载失败！");
                });

            },
        },
        components: {

        }
    }
</script>


<style scoped>
    .txtareaCom {
        border-bottom: 1px solid rgb(143, 143, 143);
        /* border-top: 1px solid rgb(143, 143, 143); */
        /* border-top: 0px; */
        padding: 10px;
        color: rgb(160, 160, 160);
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
        font-size: 14px;
    }

    .ptext {
        border-bottom: 1px solid rgb(143, 143, 143);
        padding: 10px 10px 10px 10px;
    }

    .table {
        border: 1px solid rgb(143, 143, 143);
    }
</style>

<style>
    .tableRadio .vxe-radio--label {
        display: inline;
    }

    .tableCheckbox .vxe-checkbox--label {
        display: inline;
    }

    .finished.vxe-toolbar .vxe-buttons--wrapper {
        display: block;
    }

    .mycell .vxe-cell {
        padding: 0px;
        text-align: left;
    }

    .finishedList-table .vxe-table--body-wrapper {
        height: calc(100vh - 236px);
    }
</style>